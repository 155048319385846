import React from "react";
import "../assets/css/Header.css";
import logo from "../assets/images/logo_tp.png";

export default function Navbar() {
    return (
        <div className="menubar">
            <div className="menu-icon mobile-hidden">
                <a href="https://rippaday.com">
                    <img src={logo} alt="rippaday.com" />
                    <i className="fas fa-home"></i>
                </a>
            </div>
            <div className="menu-menu">
                <a href="/" className="menu-item menu-home">
                    Home
                </a>
                <a href="https://app.rippaday.com" className="menu-item">
                    Free Tips
                </a>
                <a href="https://app.rippaday.com/Members" className="menu-item">
                    Members
                </a>
                <a
                    href="https://app.rippaday.com/first4"
                    className="menu-item mobile-hidden"
                >
                    Top 4
                </a>
                <a
                    href="https://app.rippaday.com/tools"
                    className="menu-item mobile-hidden"
                >
                    Tools
                </a>
                <a href="https://app.rippaday.com/next" className="menu-item">
                    Next to Go
                </a>
            </div>
        </div>
    );
}
